<template>
  <Page
    has-actions
    :subtitle="`${total} ${$tc('customer', total <= 1 ? 1 : 2)}`"
    :loading="loading"
  >
    <template slot="actions">
      <b-dropdown
        aria-role="list"
        position="is-bottom-left"
        scrollable
        max-height="400px"
        append-to-body
        :disabled="total == 0"
      >
        <div slot="trigger">
          <b-button
            icon-left="dots-vertical"
            type="is-default"
            rounded
          />
        </div>

        <b-dropdown-item
          aria-role="listitem"
          @click="dialog = true"
        >
          <b-icon
            icon="mail"
            size="is-small"
          />
          {{ $t("send_mailing") }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
    <Card
      v-if="$can('read', 'users.crm')"
      content-class="c-table"
    >
      <div class="header-container">
        <div class="columns is-mobile is-multiline is-flex-grow-1">
          <div class="column is-2-tablet is-12-mobile">
            <b-select-validation
              v-model="selected_site"
              required
              :label="$t('site')"
              size="is-small"
              hide-details
            >
              <template>
                <option
                  v-for="opt in sites"
                  :key="opt.id"
                  :value="opt.id"
                >
                  {{
                    opt.label
                  }}
                </option>
              </template>
            </b-select-validation>
          </div>
          <div class="column is-2-tablet is-12-mobile">
            <b-select-validation
              v-model="selected_mailling_type"
              required
              :label="$t('mail_type')"
              size="is-small"
              hide-details
            >
              <template>
                <option
                  v-for="opt in maillingTypes"
                  :key="opt.id"
                  :value="opt.id"
                >
                  {{ opt.label }}
                </option>
              </template>
            </b-select-validation>
          </div>
          <div class="column is-2-tablet is-12-mobile">
            <!-- <b-select-validation
              v-model="selected_warehouse_id"
              required
              :disabled="!selected_site"
              :label="$tc('warehouse', 1)"
              size="is-small"
              hide-details
            >
              <template>
                <option
                  v-for="opt in warehouses"
                  :key="opt.id"
                  :value="opt.id"
                >
                  {{
                    opt.country.country
                  }}
                </option>
              </template>
            </b-select-validation> -->
          </div>

          <div class="column is-6-tablet is-12-mobile">
            <div class="is-flex is-justify-content-flex-end">
              <b-button
                :disabled="!selected_site || loading"
                type="is-primary"
                outlined
                size="is-small"
                @click="addFilter()"
              >
                <b-icon
                  icon="filter-plus-outline"
                  size="is-small"
                  class="baseline mr-1"
                />
                {{ $t("add_filter") }}
              </b-button>
              <b-button
                class="ml-2"
                :disabled="!selected_site || loading"
                :loading="loading"
                type="is-primary"
                size="is-small"
                @click="sendRequest()"
              >
                <b-icon
                  icon="magnify"
                  size="is-small"
                  class="baseline mr-1"
                />
                {{ $t("search") }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <template v-if="selected_site && selected_filters.length">
        <div class="header-container pt-0">
          <div class="is-flex-grow-1">
            <transition-group
              tag="div"
              name="list"
              class="columns is-multiline is-mobile is-variable is-1 m-0"
            >
              <div
                v-for="i in selected_filters.length"
                :key="`filter_${i}`"
                class="column is-6-tablet is-12-mobile"
              >
                <FilterComponent
                  class="mb-2"
                  :selected-filters="selected_filters"
                  :selected-site="selected_site"
                  :brands="brands"
                  :categories="categories"
                  :salesmen="salesmen"
                  :warehouse-id="selected_warehouse_id"
                  :tlds="sites.find(s => s.id == selected_site).tlds"
                  :languages="sites.find(s => s.id == selected_site).languages"
                  :index="i - 1"
                  @setfilter="SelectFilter"
                  @setValues="setFilterValue"
                />
              </div>
            </transition-group>
          </div>
        </div>
      </template>
      <hr class="mt-0">
      <b-table
        :data="items"
        paginated
        pagination-position="top"
        :current-page="pagination.page"
        :per-page="pagination.itemsPerPage"
        :loading="loading"
      >
        <!-- <b-table-column
          v-slot="props"
          field="site_id"
          header-class="sticky-header"
        >
          <img
            height="20"
            width="20"
            :src="'/img/sites/' + props.row.site_id + '.svg'"
          >
        </b-table-column> -->
        <b-table-column
          v-slot="props"
          field="id"
          :label="$t('customer_id')"
          header-class="sticky-header"
        >
          <router-link :to="`/users/${props.row.id}`">
            {{
              props.row.id
            }}
          </router-link>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="fname"
          :label="$t('fname')"
          header-class="sticky-header"
        >
          {{ props.row.fname }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="lname"
          :label="$t('lname')"
          header-class="sticky-header"
        >
          {{ props.row.lname }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="email"
          :label="$t('email')"
          header-class="sticky-header"
        >
          {{ props.row.email }}
        </b-table-column>

        <EmptyBlock
          slot="empty"
          icon="account"
        />
      </b-table>
      <Pagination
        :per-page="pagination.itemsPerPage"
        :total="total"
        :current-page="pagination.page"
        @update-per-page="val => (pagination.itemsPerPage = val)"
        @update-current-page="val => (pagination.page = val)"
      />

      <b-modal
        v-model="dialog"
        :width="460"
      >
        <Card>
          <div slot="header">
            <div class="is-size-5 px py-4">
              <b>{{ $t("send_mailing") + ": " + total }}</b>
            </div>
            <hr class="my-0">
          </div>
          <div class="columns is-multiline is-mobile">
            <div class="column is-12">
              <b-switch
                v-model="createCashback"
                type="is-primary"
              >
                {{
                  $t("create_cashback")
                }}
              </b-switch>
            </div>
            <template
              v-if="
                createCashback &&
                  send_mailing.data &&
                  send_mailing.data.length != 0
              "
            >
              <div class="column is-12">
                <b-field :label="$t('expire_at')">
                  <b-datepicker
                    v-model="send_mailing.expire_at"
                    :min-date="new Date(min_date)"
                    editable
                    trap-focus
                  />
                </b-field>
              </div>
              <template v-for="item in send_mailing.data">
                <div
                  :key="item.currency + '-amount'"
                  class="column is-6-tablet is-12-mobile"
                >
                  <b-input-validation
                    v-model="item.amount"
                    :label="$t('amount')"
                    :placeholder="placeholders.price.decimal"
                    :vid="item.currency + '-amount'"
                    input-type="number"
                    step=".01"
                  />
                </div>
                <div
                  :key="item.currency + '-currency'"
                  class="column is-6-tablet is-12-mobile"
                >
                  <b-input-validation
                    v-model="item.currency"
                    :label="$t('currency_code')"
                    disabled
                  />
                </div>
              </template>
            </template>
            <div
              v-else-if="createCashback"
              class="column is-12 has-text-danger"
            >
              {{ $t("please_select_tld_order") }}
            </div>
            <div class="column is-12">
              <b-button
                type="is-success"
                class="mr-3"
                :disabled="loading_dialog"
                :loading="loading_dialog"
                @click="sendMailing()"
              >
                {{ $t("validate") }}
              </b-button>

              <b-button
                :disabled="loading_dialog"
                outlined
                @click="dialog = false"
              >
                {{ $t("cancel") }}
              </b-button>
            </div>
          </div>
        </Card>
      </b-modal>
    </Card>
  </Page>
</template>

<script>
import FilterComponent from "@/components/modules/crm/filter.vue";

import debounce from "debounce";
import moment from "moment";

export default {
  components: {
    FilterComponent
  },
  data() {
    return {
      dialog: false,
      loading: true,
      loading_dialog: false,
      menu: false,
      createCashback: false,
      maillingTypes: [
        { label: this.$t("newsletter"), id: "newsletter" },
        { label: this.$t("legal"), id: "legal" }
      ],
      total: 0,
      rowsPerPage: [50, 100, 200],
      headers: [
        // { text: "", align: "left", value: "site_id" },
        { text: this.$i18n.t("customer_id"), align: "left", value: "id" },
        { text: this.$i18n.t("fname"), align: "left", value: "fname" },
        { text: this.$i18n.t("lname"), align: "left", value: "lname" },
        { text: this.$i18n.t("email"), align: "left", value: "email" }
      ],
      pagination: {
        page: 1,
        itemsPerPage: 50
      },
      items: [],

      sites: [],
      brands: [],
      categories: [],
      salesmen: [],

      selected_site: null,
      selected_warehouse_id: null,
      selected_mailling_type: "newsletter",
      selected_filters: [],
      filter_value: [],
      send_mailing: {}
    };
  },
  computed: {
    warehouses(){
      return this.selected_site ? this.sites.find(s=> s.id == this.selected_site).warehouses : [];
    },
    min_date() {
      return moment()
        .add(1, "years")
        .format("YYYY-MM-DD");
    }
  },
  watch: {
    "pagination.itemsPerPage": function() {
      this.sendRequest();
    },
    "pagination.page": function() {
      this.sendRequest();
    },
    selected_site(newVal) {
      if(newVal) this.selected_warehouse_id = this.sites.find(s=> s.id ==  newVal).warehouses[0].id;
    }
  },
  created() {
    this.updateTitle(this.$i18n.t("mailing"));
    this.sendRequest();
  },
  mounted() {
    this.send_mailing.expire_at = new Date(this.min_date);
  },
  methods: {
    init(data) {
      this.sites = data.filters.sites;
      this.brands = data.filters.brands;
      this.categories = this.setCategories(data.filters.categories);
      this.salesmen = data.filters.salesmen;
    },
    addFilter() {
      this.selected_filters.push(null);
      this.filter_value.push({
        filter_type: null,
        value: null
      });
    },
    SelectFilter(index, item) {
      this.selected_filters[index] = item;
      this.filter_value[index] = {
        filter_type: item,
        value: null
      };
    },
    setFilterValue(index, value) {
      this.filter_value[index].value = value;
    },
    sendRequest: debounce(function() {
      if (this.$can("read", "users.crm")) {
        let api_params = "";
        this.loading = true;
        if (this.selected_site) {
          api_params = `?site_id=${this.selected_site}&type=${this.selected_mailling_type}&page=${this.pagination.page}&per_page=${this.pagination.itemsPerPage}`;

          let filters = [];
          for (let i = 0; i < this.filter_value.length; i++) {
            const filter_value = this.filter_value[i];
            if (filter_value.filter_type && filter_value.value) {
              let obj = {
                id: filter_value.filter_type
              };
              if (filter_value.value.operator) {
                obj.selected = filter_value.value.value;
                obj.operator = filter_value.value.operator;
              } else {
                obj.selected = filter_value.value;
              }
              filters.push(obj);
            }
          }

          if (filters.length)
            api_params += `&filters=${btoa(JSON.stringify(filters))}`;
        }
        this.$axios
          .get(`crm/emailing${api_params}`)
          .then(res => {
            this.items = res.data.list;
            this.total =
              (res.data.pagination && res.data.pagination.total) || 0;
            this.currencies = res.data.currencies || [];
            this.send_mailing.data = [];
            for (let i = 0; i < this.currencies.length; i++) {
              const element = this.currencies[i];
              this.send_mailing.data.push({
                amount: null,
                currency: element
              });
            }
            this.init(res.data);
          })
          .catch(e => this.$root.clientError(e))
          .finally(() => (this.loading = false));
      }
    }, 1000),
    sendMailing() {
      // if (this.$can("read", "users.crm")) {
      this.loading_dialog = true;
      let api_params = `?site_id=${this.selected_site}&type=${this.selected_mailling_type}`;
      let filters = [];
      for (let i = 0; i < this.filter_value.length; i++) {
        const filter_value = this.filter_value[i];
        if (filter_value.filter_type && filter_value.value) {
          let obj = {
            id: filter_value.filter_type
          };
          if (filter_value.value.operator) {
            obj.selected = filter_value.value.value;
            obj.operator = filter_value.value.operator;
          } else {
            obj.selected = filter_value.value;
          }
          filters.push(obj);
        }
      }
      let data = {
        cashback: null
      };
      if (this.createCashback) {
        data = {
          cashback: {
            expire_at: moment(this.send_mailing.expire_at).format("YYYY-MM-DD"),
            amount: {}
          }
        };
        for (let i = 0; i < this.send_mailing.data.length; i++) {
          const element = this.send_mailing.data[i];
          data.cashback.amount[element.currency] = element.amount;
        }
      }

      api_params += `&filters=${btoa(JSON.stringify(filters))}`;
      this.$axios
        .post(`crm/emailing/import${api_params}`, data)
        .then(() => {
          this.dialog = false;
        })
        .catch(e => this.$root.clientError(e))
        .finally(() => (this.loading_dialog = false));
      // }
    },
    setCategories(cats) {
      let categories = {};
      //   setting level 1 categories
      categories["1"] = cats;
      //   setting level 2 categories
      categories["2"] = cats.reduce((arr, c) => {
        if (c.children) return [...arr, ...c.children];
        return arr;
      }, []);
      //   setting level 3 categories
      categories["3"] = [];
      for (let i = 0; i < cats.length; i++) {
        const c = cats[i].children;
        if (c) {
          let children = c.reduce((arr, c) => {
            if (c.children) return [...arr, ...c.children];
            return arr;
          }, []);
          categories[3] = [...categories[3], ...children];
        }
      }
      return categories;
    }
  }
};
</script>
