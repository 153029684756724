<template>
  <div class="wrap">
    <div class="columns is-mobile filter-crm m-0">
      <div
        :class="
          ['dates_order', 'dates_account'].indexOf(selectedFilterType) != -1
            ? 'is-4'
            : 'is-5'
        "
        class="column"
      >
        <b-select-validation
          :label="$t('filter_type')"
          size="is-small"
          hide-details
          @input="SelectFilter"
        >
          <template>
            <option
              v-for="opt in filterTypes"
              :key="opt.id"
              :value="opt.id"
              :disabled="is_disabled(opt)"
            >
              {{ opt.value }}
            </option>
          </template>
        </b-select-validation>
      </div>
      <div
        v-if="
          ['avg_cart', 'num_orders', 'turnover', 'brands', 'tlds'].indexOf(
            selectedFilterType
          ) != -1
        "
        class="column is-2"
      >
        <b-select-validation
          :value="selectedOperator"
          required
          label=""
          size="is-small"
          hide-details
          @input="selectOperator"
        >
          <template>
            <option
              v-for="opt in opratorItems"
              :key="opt.id"
              :value="opt.id"
            >
              {{
                opt.value
              }}
            </option>
          </template>
        </b-select-validation>
      </div>

      <div
        :class="
          ['avg_cart', 'num_orders', 'turnover', 'brands', 'tlds'].indexOf(
            selectedFilterType
          ) != -1
            ? 'is-5'
            : selectedFilterType == 'dates_order' ||
              selectedFilterType == 'dates_account'
              ? 'is-4'
              : 'is-7'
        "
        class="column"
      >
        <b-field
          v-if="selectedFilterType == 'brands'"
          :label="filterTypes.find(e => e.id == selectedFilterType).value"
        >
          <b-taginput
            ref="taginput"
            v-model="selectedBrands"
            :data="filteredBrands"
            autocomplete
            open-on-focus
            size="is-small"
            multiple
            ellipsis
            :disabled="brands.length == 0"
            :placeholder="selectedBrands.length == 0 ? $t('choose') : ''"
            aria-close-label="Delete this tag"
            @input="
              emitValues({
                value: selectedBrands.reduce((arr, b) => [...arr, b.id], []),
                operator: selectedOperator
              })
            "
            @typing="filterBrands"
          >
            <template slot-scope="props">
              {{ props.option.label }}
            </template>
            <template
              slot="selected"
              slot-scope="props"
            >
              <b-tag
                v-for="(tag, brandIndex) in props.tags"
                :key="brandIndex"
                type="is-default"
                :tabstop="false"
                closable
                @close="$refs.taginput.removeTag(brandIndex, $event)"
              >
                {{ tag.label }}
              </b-tag>
            </template>
          </b-taginput>
        </b-field>

        <b-field
          v-if="selectedFilterType == 'tlds'"
          :label="filterTypes.find(e => e.id == selectedFilterType).value"
        >
          <b-taginput
            ref="taginput"
            v-model="selectedTlds"
            :data="tlds"
            autocomplete
            open-on-focus
            size="is-small"
            multiple
            ellipsis
            
            :placeholder="selectedTlds.length == 0 ? $t('choose') : ''"
            aria-close-label="Delete this tag"
            @input="
              emitValues({
                value: selectedTlds.reduce((arr, b) => [...arr, b.id], []),
                operator: selectedOperator
              })
            "
            @typing="filteredTlds"
          >
            <template slot-scope="props">
              {{ props.option.country.label }}
            </template>
            <template
              slot="selected"
              slot-scope="props"
            >
              <b-tag
                v-for="(tag, tldIndex) in props.tags"
                :key="tldIndex"
                type="is-default"
                :tabstop="false"
                closable
                @close="$refs.taginput.removeTag(tldIndex, $event)"
              >
                {{ tag.country.label }}
              </b-tag>
            </template>
          </b-taginput>
        </b-field>


        <b-field
          v-else-if="
            ['cat_1', 'cat_2', 'cat_3'].indexOf(selectedFilterType) != -1
          "
          :label="filterTypes.find(e => e.id == selectedFilterType).value"
        >
          <b-taginput
            ref="taginput"
            v-model="selectedCategories"
            :data="filteredCategories"
            :placeholder="selectedCategories.length == 0 ? $t('choose') : ''"
            autocomplete
            open-on-focus
            size="is-small"
            :disabled="categories[selectedFilterType.split('_')[1]].length == 0"
            multiple
            ellipsis
            aria-close-label="Delete this tag"
            @input="
              emitValues(
                selectedCategories.reduce((arr, b) => [...arr, b.id], [])
              )
            "
            @typing="filterCategories"
          >
            <template slot-scope="props">
              {{ props.option.label }}
            </template>
            <template
              slot="selected"
              slot-scope="props"
            >
              <b-tag
                v-for="(tag, catIndex) in props.tags"
                :key="catIndex"
                type="is-default"
                :tabstop="false"
                closable
                @close="$refs.taginput.removeTag(catIndex, $event)"
              >
                {{ tag.label }}
              </b-tag>
            </template>
          </b-taginput>
        </b-field>

        <SearchProduct
          v-else-if="selectedFilterType == 'products'"
          :label="filterTypes.find(e => e.id == selectedFilterType).value"
          type="size"
          size="is-small"
          autofocus
          hide-details
          dense
          :warehouse-id="warehouseId"
          :sites-ids="[selectedSite]"
          @selectItem="selectProduct"
        />

        <SearchProduct
          v-else-if="selectedFilterType == 'skus'"
          :label="filterTypes.find(e => e.id == selectedFilterType).value"
          type="sku"
          size="is-small"
          autofocus
          hide-details
          dense
          :warehouse-id="warehouseId"
          :sites-ids="[selectedSite]"
          @selectItem="selectSku"
        />

        <SearchProduct
          v-else-if="selectedFilterType == 'packs'"
          :label="filterTypes.find(e => e.id == selectedFilterType).value"
          type="pack"
          size="is-small"
          autofocus
          hide-details
          dense
          :warehouse-id="warehouseId"
          :sites-ids="[selectedSite]"
          @selectItem="selectPack"
        />

        <b-field
          v-else-if="selectedFilterType == 'agents'"
          :label="filterTypes.find(e => e.id == selectedFilterType).value"
        >
          <b-taginput
            ref="taginput"
            v-model="selectedSalesmen"
            size="is-small"
            :data="filteredSalesmen"
            :placeholder="selectedSalesmen.length == 0 ? $t('choose') : ''"
            autocomplete
            :disabled="salesmen.length == 0"
            open-on-focus
            multiple
            ellipsis
            aria-close-label="Delete this tag"
            @input="
              emitValues(
                selectedSalesmen.reduce((arr, s) => [...arr, s.id], [])
              )
            "
            @typing="filterSalesmen"
          >
            <template slot-scope="props">
              {{
                `${props.option.fname} ${props.option.lname}`
              }}
            </template>
            <template
              slot="selected"
              slot-scope="props"
            >
              <b-tag
                v-for="(tag, salesmanIndex) in props.tags"
                :key="salesmanIndex"
                type="is-default"
                :tabstop="false"
                closable
                @close="$refs.taginput.removeTag(salesmanIndex, $event)"
              >
                {{ `${tag.fname} ${tag.lname}` }}
              </b-tag>
            </template>
          </b-taginput>
        </b-field>

        <b-input-validation
          v-else-if="selectedFilterType == 'avg_cart'"
          v-model="avgCartValue"
          size="is-small"
          :label="filterTypes.find(e => e.id == selectedFilterType).value"
          vid="avg_cart"
          :placeholder="placeholders.price.decimal"
          autofocus
          input-type="number"
          hide-details
          @blur="checkNegatifValue(avgCartValue)"
          @input="parseIntAndEmit(avgCartValue)"
        />

        <b-input-validation
          v-else-if="selectedFilterType == 'num_orders'"
          v-model="ordersNumberValue"
          size="is-small"
          :label="filterTypes.find(e => e.id == selectedFilterType).value"
          vid="num_orders"
          :placeholder="placeholders.price.int"
          autofocus
          input-type="number"
          hide-details
          @blur="checkNegatifValue(ordersNumberValue)"
          @input="parseIntAndEmit(ordersNumberValue)"
        />

        <b-input-validation
          v-else-if="selectedFilterType == 'days_without_orders'"
          v-model="daysWithoutOrdersValue"
          size="is-small"
          :label="filterTypes.find(e => e.id == selectedFilterType).value"
          vid="days_without_orders"
          :placeholder="placeholders.price.int"
          autofocus
          input-type="number"
          hide-details
          @blur="checkNegatifValue(daysWithoutOrdersValue)"
          @input="parseIntAndEmit(daysWithoutOrdersValue)"
        />

        <b-input-validation
          v-else-if="selectedFilterType == 'turnover'"
          v-model="turnoverValue"
          size="is-small"
          :label="filterTypes.find(e => e.id == selectedFilterType).value"
          vid="turnover"
          :placeholder="placeholders.price.decimal"
          autofocus
          input-type="number"
          hide-details
          @blur="checkNegatifValue(turnoverValue)"
          @input="parseIntAndEmit(turnoverValue)"
        />

        <!-- <b-select-validation
          v-else-if="selectedFilterType == 'tlds'"
          v-model="selectedTld"
          size="is-small"
          required
          hide-details
          :label="filterTypes.find(e => e.id == selectedFilterType).value"
          @input="emitValues(selectedTld)"
        >
          <template>
            <option
              v-for="opt in tlds"
              :key="opt.id"
              :value="opt.id"
            >
              {{
                opt.label
              }}
            </option>
          </template>
        </b-select-validation> -->

        <b-select-validation
          v-else-if="selectedFilterType == 'languages'"
          v-model="selectedLanguage"
          size="is-small"
          :placeholder="$t('choose')"
          required
          hide-details
          :label="filterTypes.find(e => e.id == selectedFilterType).value"
          @input="emitValues(selectedLanguage)"
        >
          <template>
            <option
              v-for="opt in languages"
              :key="opt.iso"
              :value="opt.iso"
            >
              {{
                opt.label
              }}
            </option>
          </template>
        </b-select-validation>

        <b-field
          v-else-if="selectedFilterType == 'vouchers'"
          :label="$tc('code', 1)"
        >
          <b-autocomplete
            v-model="selectedVoucher"
            size="is-small"
            :placeholder="$t('type_verb')"
            :data="vouchers"
            field="voucher"
            expanded
            :open-on-focus="false"
            :clearable="false"
            :loading="loadingVouchers"
            @typing="getVouchers"
            @select="selectVoucher"
          >
            <template slot-scope="props">
              {{ props.option.voucher }}
            </template>
            <template slot="empty">
              {{ $t("no_result_found") }}
            </template>
          </b-autocomplete>
        </b-field>

        <b-input-validation
          v-else-if="selectedFilterType == 'deal'"
          v-model="dealValue"
          size="is-small"
          :label="filterTypes.find(e => e.id == selectedFilterType).value"
          vid="turnover"
          :placeholder="placeholders.number['2']"
          autofocus
          input-type="number"
          hide-details
          @blur="checkNegatifValue(dealValue)"
          @input="parseIntAndEmit(dealValue)"
        />

        <b-field
          v-else-if="
            selectedFilterType == 'dates_order' ||
              selectedFilterType == 'dates_account'
          "
          :label="$t('from')"
        >
          <b-datepicker
            v-model="selectedDateFrom"
            size="is-small"
            :placeholder="placeholders.date.day"
            editable
            :min-date="new Date('01/01/2013')"
            trap-focus
          />
        </b-field>

        <!-- <b-select-validation v-else /> -->
      </div>

      <div
        v-if="
          selectedFilterType == 'dates_order' ||
            selectedFilterType == 'dates_account'
        "
        class="column is-4"
      >
        <b-field :label="$t('till')">
          <b-datepicker
            v-model="selectedDateTo"
            size="is-small"
            :placeholder="placeholders.date.day"
            :min-date="selectedDateFrom ? selectedDateFrom : new Date('01/01/2013')"
            editable
            trap-focus
          />
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import SearchProduct from "@/components/forms/elements/searchProduct.vue";

import moment from "moment";
import debounce from "debounce";

export default {
  components: {
    SearchProduct
  },
  props: {
    selectedFilters: {
      type: Array,
      required: true
    },
    selectedSite: {
      type: [Number, String],
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    brands: {
      type: Array,
      required: true
    },
    categories: {
      type: Object,
      required: true
    },
    salesmen: {
      type: Array,
      required: true
    },
    tlds: {
      type: Array,
      required: true
    },
    languages: {
      type: Array,
      required: true
    },
    warehouseId: {
      type: [Number, String],
      default: null
    },
  },
  data() {
    return {
      menu: false,
      menu2: false,
      loadingVouchers: false,
      filterTypes: [
        // single value
        {
          value: this.$t("tld"),
          id: "tlds"
        },
        {
          value: this.$tc("language", 1),
          id: "languages"
        },
        // multiple
        {
          value: this.$tc("brand", 2),
          id: "brands"
        },
        {
          value: `${this.$tc("category", 2)} level 1`,
          id: "cat_1"
        },
        {
          value: `${this.$tc("category", 2)} level 2`,
          id: "cat_2"
        },
        {
          value: `${this.$tc("category", 2)} level 3`,
          id: "cat_3"
        },
        {
          value: this.$tc("pack", 2),
          id: "packs"
        },
        {
          value: this.$tc("product", 2),
          id: "products"
        },
        {
          value: this.$tc("sku", 1),
          id: "skus"
        },
        {
          value: this.$tc("agent", 2),
          id: "agents"
        },
        {
          value: this.$tc("voucher", 1),
          id: "vouchers"
        },
        {
          value: this.$tc("deal", 1),
          id: "deal"
        },
        // single value
        {
          value: this.$t("avg_cart"),
          id: "avg_cart"
        },
        {
          value: this.$t("orders_number"),
          id: "num_orders"
        },
        {
          value: this.$t("days_without_orders"),
          id: "days_without_orders"
        },
        {
          value: this.$t("turnover"),
          id: "turnover"
        },

        // date value
        {
          value: `${this.$t("dates_order")} `,
          id: "dates_order"
        },
        {
          value: this.$t("dates_creation"),
          id: "dates_account"
        }
      ],
      selectedFilterType: null,
      selectedOperator: "gte",
      selectedBrands: [],
      selectedCategories: [],
      selectedPacks: [],
      selectedProducts: [],
      selectedSkus: [],
      selectedSalesmen: [],
      avgCartValue: null,
      ordersNumberValue: null,
      daysWithoutOrdersValue: null,
      turnoverValue: null,
      selectedTlds: [],
      selectedVoucher: null,
      dealValue: null,
      selectedLanguage: null,
      selectedDateFrom: null,
      selectedDateTo: null,

      vouchers: [],
      filteredBrands: this.brands,
      filteredTlds: this.tlds,
      filteredCategories: [],
      filteredSalesmen: this.salesmen
    };
  },
  computed: {
    opratorItems() {
      return ["avg_cart", "num_orders", "turnover"].indexOf(
        this.selectedFilterType
      ) != -1
        ? [
            { id: "lte", value: "<=" },
            { id: "gte", value: ">=" }
          ]
        : [
            { id: "in", value: "In" },
            { id: "not_in", value: "Not in" }
          ];
    }
  },
  watch: {
    selectedSite() {
      if (this.selectedFilterType == "tlds") {
        if (this.tlds.length == 1) {
          this.selectedTld = this.tlds[0].id;
        } else {
          this.selectedTld = "";
        }
        this.emitValues(this.selectedTld);
      }
      if (this.selectedFilterType == "languages") {
        if (this.languages.length == 1) {
          this.selectedLanguage = this.languages[0].iso;
        } else {
          this.selectedLanguage = "";
        }
        this.emitValues(this.selectedLanguage);
      }
    },
    selectedDateFrom(value) {
      this.emitValues({
        from: moment(value).format("YYYY-MM-DD"),
        to: this.selectedDateTo
          ? moment(this.selectedDateTo).format("YYYY-MM-DD")
          : null
      });
    },
    selectedDateTo(value) {
      this.emitValues({
        from: this.selectedDateFrom
          ? moment(this.selectedDateFrom).format("YYYY-MM-DD")
          : null,
        to: moment(value).format("YYYY-MM-DD")
      });
    },
    selectedFilterType(value) {
      if (value == "tlds" && this.tlds.length == 1) {
        this.selectedTld = this.tlds[0].id;
        this.emitValues(this.selectedTld);
      }
      if (value == "languages" && this.languages.length == 1) {
        this.selectedLanguage = this.languages[0].iso;
        this.emitValues(this.selectedLanguage);
      }
      if (value.indexOf("cat") != -1) {
        this.filteredCategories = this.categories[
          this.selectedFilterType.split("_")[1]
        ];
      }
    }
  },
  methods: {
    selectVoucher(v) {
      if (v) this.emitValues(v.id);
      else this.emitValues(null);
    },
    getVouchers: debounce(function(str) {
      if(!str) return this.vouchers = [];
      this.loadingVouchers = true
      this.$axios
        .post("ecommerce/vouchers/search", {
          site_id: this.selectedSite,
          voucher: str
        })
        .then(res => {
          this.vouchers = res.data.list;
        })
        .catch(e => this.clientError(e))
      .finally(() => (this.loadingVouchers = false));
    }, 200),
    filterBrands(search) {
      this.filteredBrands = this.brands.filter(option => {
        return (
          JSON.stringify(option.label)
            .toLowerCase()
            .indexOf(search.toLowerCase()) != -1
        );
      });
    },
    filterCategories(search) {
      let categoriesLevel = this.categories[
        this.selectedFilterType.split("_")[1]
      ];
      this.filteredCategories = categoriesLevel.filter(option => {
        return (
          JSON.stringify(option.label)
            .toLowerCase()
            .indexOf(search.toLowerCase()) != -1
        );
      });
    },
    filterSalesmen(search) {
      this.filteredSalesmen = this.salesmen.filter(option => {
        return (
          JSON.stringify(option.name)
            .toLowerCase()
            .indexOf(search.toLowerCase()) != -1
        );
      });
    },
    selectProduct(product) {
      this.selectedProducts = [product.id];
      this.emitValues(this.selectedProducts);
    },
    selectSku(product) {
      this.selectedSkus = [product.id];
      this.emitValues(this.selectedSkus);
    },
    selectPack(pack) {
      this.selectedPacks = [pack.id];
      this.emitValues(this.selectedPacks);
    },
    SelectFilter(item) {
      this.selectedFilterType = item;
      if (item == "brands" || item == "tlds" ) this.selectedOperator = "in";
      else if (
        ["avg_cart", "num_orders", "turnover"].indexOf(
          this.selectedFilterType
        ) != -1
      )
        this.selectedOperator = "gte";
      this.$emit("setfilter", this.index, item);
    },
    checkNegatifValue(value) {
      if (value < 0) value = 0;
      switch (this.selectedFilterType) {
        case "avg_cart":
          this.avgCartValue = value;
          this.emitValues({ value: value, operator: this.selectedOperator });
          break;
        case "num_orders":
          this.ordersNumberValue = value;
          this.emitValues({ value: value, operator: this.selectedOperator });
          break;
        case "days_without_orders":
          this.daysWithoutOrdersValue = value;
          this.emitValues(value);
          break;
        case "turnover":
          this.turnoverValue = value;
          this.emitValues({ value: value, operator: this.selectedOperator });
          break;
        case "deal":
          this.dealValue = value;
          this.emitValues(value);
          break;
        default:
          break;
      }
    },
    parseIntAndEmit(value) {
      if (!isNaN(parseInt(value))) value = parseInt(value);
      switch (this.selectedFilterType) {
        case "avg_cart":
          this.avgCartValue = value;
          this.emitValues({ value: value, operator: this.selectedOperator });
          break;
        case "num_orders":
          this.ordersNumberValue = value;
          this.emitValues({ value: value, operator: this.selectedOperator });
          break;
        case "days_without_orders":
          this.daysWithoutOrdersValue = value;
          this.emitValues(value);
          break;
        case "turnover":
          this.turnoverValue = value;
          this.emitValues({ value: value, operator: this.selectedOperator });
          break;
        case "deal":
          this.dealValue = value;
          this.emitValues(value);
          break;
        default:
          break;
      }
    },
    selectOperator(e) {
      this.selectedOperator = e;
      switch (this.selectedFilterType) {
        case "avg_cart":
          this.emitValues({
            value: this.avgCartValue,
            operator: this.selectedOperator
          });
          break;
        case "num_orders":
          this.emitValues({
            value: this.ordersNumberValue,
            operator: this.selectedOperator
          });
          break;
        case "brands":
          this.emitValues({
            value: this.selectedBrands.reduce((arr, b) => [...arr, b.id], []),
            operator: this.selectedOperator
          });
          break;
          case "tlds":
          this.emitValues({
            value: this.selectedTlds.reduce((arr, b) => [...arr, b.id], []),
            operator: this.selectedOperator
          });
          break;
        case "turnover":
          this.emitValues({
            value: this.turnoverValue,
            operator: this.selectedOperator
          });
          break;
        default:
          break;
      }
    },
    emitValues(values) {
      this.$emit("setValues", this.index, values);
    },
    is_disabled(item) {
      if (
        (this.selectedFilters.indexOf("cat_1") != -1 ||
          this.selectedFilters.indexOf("cat_2") != -1 ||
          this.selectedFilters.indexOf("cat_3") != -1) &&
        ["cat_1", "cat_2", "cat_3"].indexOf(item.id) != -1
      ) {
        return true;
      }
      return this.selectedFilters.indexOf(item.id) != -1;
    }
  }
};
</script>
